export function closeMenu(elem) {
  Array.prototype.forEach.call(elem.querySelectorAll('nav > ul > li'), (el) => {
    el.classList.remove('selected');
    el.classList.remove('not-selected');
  });
  Array.prototype.forEach.call(elem.querySelectorAll('nav ~ div'), (el) =>
    el.classList.remove('open'),
  );
  document.body.dataset.mainMenu = 'closed';
}

export function categoryMenu(elem) {
  if (!('mainMenu' in document.body.dataset)) {
    document.body.dataset.mainMenu = 'closed';
  }
  let openTimeout;
  let closeTimeout;

  // This is the basic handler for opening and closing the menu, split out
  // into a partial function so we can call it differently for touch/hover
  // events as needed
  const handler = (ev) => {
    const target = document.getElementById(
      (
        (ev.target ? ev.target : ev.currentTarget).dataset.categoryMenuTarget ||
        ''
      ).slice(1),
    );

    if (!target) {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();

    // Set `selected` class only on selected tab and `not-selected` on
    // everything else
    Array.prototype.forEach.call(
      target.parentNode.parentNode.children,
      (elem) => {
        if (elem !== ev.target.parentNode) {
          elem.classList.remove('selected');
        }
        elem.classList.remove('not-selected');
      },
    );
    target.parentNode.classList.toggle('selected');
    if (target.parentNode.classList.contains('selected')) {
      Array.prototype.forEach.call(
        target.parentNode.parentNode.children,
        (elem) => {
          if (elem !== ev.target.parentNode) {
            elem.classList.add('not-selected');
          }
        },
      );
    }

    // Set menu open/closed status on body
    document.body.dataset.mainMenu = target.parentNode.classList.contains(
      'selected',
    )
      ? 'open'
      : 'closed';
  };
  const html = document.getElementsByTagName('html')[0];

  // Set up handlers for each menu button
  Array.prototype.forEach.call(
    elem.querySelectorAll('nav > ul > li > button'),
    (elem) => {
      elem.addEventListener('click', (ev) => {
        const hasTouch = html.classList.contains('has-touch');
        const hasHover = html.classList.contains('has-hover');
        if (hasTouch || !hasHover) {
          // If we're on a touch device we open/close the menu on click...
          handler(ev);
        } else {
          // ...on a non-touch device we open on hover (handled in the next
          // event handler) and click through to the category landing page.
          ev.target.parentNode.querySelector('a').click();
        }
      });
      elem.addEventListener('mouseover', (ev) => {
        const hasTouch = html.classList.contains('has-touch');
        const hasHover = html.classList.contains('has-hover');
        // Only handle mouseover if it looks like we're on a non-touch
        // platform
        if (!hasTouch && hasHover) {
          clearTimeout(closeTimeout);
          // Don't close the currently open item on hover
          if (!elem.parentNode.classList.contains('selected')) {
            // Cancel action before it's run if the mouse leaves the element
            const leaveHandler = () => {
              clearTimeout(openTimeout);
              elem.removeEventListener('mouseleave', leaveHandler);
            };
            elem.addEventListener('mouseleave', leaveHandler);

            const overHandler = (ev) => {
              elem.removeEventListener('mouseleave', leaveHandler);
              handler(ev);
            };
            openTimeout = setTimeout(overHandler, 400, ev);
          }
        }
      });
    },
  );

  elem.querySelector('nav').addEventListener('mouseover', () => {
    const hasTouch = html.classList.contains('has-touch');
    const hasHover = html.classList.contains('has-hover');
    // If we're on a non-touch device, cancel closing the menu every time we
    // mouseover it
    if (!hasTouch && hasHover) {
      clearTimeout(closeTimeout);
    }
  });

  const closeHandler = (ev, delay = 0) => {
    const mobileMenuElem = document.querySelector('[data-mobile-menu-toggle]');
    // If we click or hover outside the menu we should close it
    if (!elem.contains(ev.target) && mobileMenuElem !== ev.target) {
      // Cancel any pending open
      clearTimeout(openTimeout);
      if (document.body.dataset.mainMenu === 'open') {
        // If the menu is already open close it in `delay`ms
        ev.preventDefault();
        ev.stopPropagation();
        closeTimeout = setTimeout(closeMenu, delay, elem);
      }
    }
  };
  document.addEventListener('click', closeHandler);

  const closeHandlerHover = (ev) => {
    const hasTouch = html.classList.contains('has-touch');
    const hasHover = html.classList.contains('has-hover');
    if (!hasTouch && hasHover) {
      closeHandler(ev, 400);
    }
  };
  document.addEventListener('mouseover', closeHandlerHover);

  const cleanupHandler = (ev) => {
    if (ev.detail.shouldSwap && ev.detail.boosted) {
      document.removeEventListener('click', closeHandler);
      document.removeEventListener('mouseover', closeHandlerHover);
      document.removeEventListener('htmx:beforeSwap', cleanupHandler);
      closeHandler();
    }
  };
  document.addEventListener('htmx:beforeSwap', cleanupHandler);
}
