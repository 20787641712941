/*
 * Simple Scroller Component
 *
 * Expected markup:
 *
 *     <div data-scroller>
 *       <ul>
 *         <li>...</li>
 *         <li>...</li>
 *         ...
 *       </ul>
 *     </div>
 *
 */

import { debounce } from "lodash-es";

export function scroller(elem) {
  const scroller = elem.querySelector("ul");
  const slides = scroller.getElementsByTagName("li");

  const controls = document.createElement("ul");
  controls.classList.add("controls");
  controls.setAttribute("aria-label", "scroller controls");
  controls.innerHTML = `
      <li>
        <button class="previous animated bounce" aria-label="previous">
          <svg aria-hidden="true" focusable="false" viewBox="0 0 20 20">
            <path d="M14.7 20L3.5 10 14.7 0l1.2 1.4L6.4 10l9.5 8.6z"/>
          </svg>
        </button>
      </li>
      <li>
        <button class="next animated bounce" aria-label="next">
          <svg aria-hidden="true" focusable="false" viewBox="0 0 20 20">
            <path d="M5.3 0l11.1 10L5.3 20 4 18.6l9.6-8.6L4 1.4z"/>
          </svg>
        </button>
      </li>
      `;

  elem.insertBefore(controls, elem.firstChild);

  const prev = controls.querySelector(".previous");
  const next = controls.querySelector(".next");

  const setControlState = () => {
    if (
      scroller.scrollLeft <= 0 ||
      scroller.scrollLeft < scroller.firstChild.clientWidth / 2
    ) {
      prev.disabled = true;
    } else {
      prev.disabled = false;
    }
    if (scroller.scrollLeft >= scroller.scrollWidth - scroller.clientWidth) {
      next.disabled = true;
    } else {
      next.disabled = false;
    }
  };

  const debouncedSetControlState = debounce(setControlState, 300);

  window.addEventListener("resize", debouncedSetControlState);
  scroller.addEventListener("scroll", debouncedSetControlState);
  scroller.addEventListener("load", debouncedSetControlState);
  scroller.addEventListener("htmx:load", debouncedSetControlState);
  Array.prototype.forEach.call(scroller.querySelectorAll("img"), (img) => {
    img.addEventListener("load", debouncedSetControlState);
  });

  controls.addEventListener("click", (e) => {
    const chunk =
      [...slides].reduce(
        (acc, el) => acc + el.getBoundingClientRect().width,
        0,
      ) / slides.length;
    const button = e.target.closest("button");
    if (!button) return;
    const dir = button.classList.contains("previous") ? "previous" : "next";
    if (dir === "previous") {
      scroller.scrollBy({ left: -chunk, top: 0, behaviour: "smooth" });
    } else {
      scroller.scrollBy({ left: +chunk, top: 0, behaviour: "smooth" });
    }
  });

  window.setTimeout(setControlState, 100);
}
