/*
 * Base non-framework JS. Use this for any generic vanilla JS snippets that
 * apply site wide.
 *
 * This will be wrapped in a closure when processed by rollup
 *
 * In general try and only initialise things once if the script were to be
 * reloaded on the page (by a `hx-boost`ed anchor, for example) either by
 * storing a variable on the window.BaseJS object or by changing a data-
 * attribute or similar.
 */
import 'regenerator-runtime'; // For babel/async-await functionality
import {debounce} from 'lodash-es';
import {scroller} from './base/scroller.js';
import {categoryMenu} from './base/category-menu.js';
import {mobileMenuToggle, mobileSearchToggle} from './base/mobile-menus.js';

window.BaseJS = window.BaseJS || {};

/*
 * Initialise main category menu and mobile menu togglers.
 */
(function () {
  const menu = document.getElementById('primary-nav');
  const search = document.getElementById('primary-search');
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-category-menu]'),
    (elem) => {
      try {
        categoryMenu(elem);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  );
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-mobile-search-toggle]'),
    (elem) => {
      if (elem.dataset.mobileSearchToggle === 'inited') {
        return;
      }
      try {
        mobileSearchToggle(elem, {target: search, menuTarget: menu});
        elem.dataset.mobileSearchToggle = 'inited';
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  );
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-mobile-menu-toggle]'),
    (elem) => {
      if (elem.dataset.mobileMenuToggle === 'inited') {
        return;
      }
      try {
        mobileMenuToggle(elem, {target: menu, searchTarget: search});
        elem.dataset.mobileMenuToggle = 'inited';
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  );
})();

/*
 * scroller
 * Simple scrolling, carousel type, UI element
 */
function initScroller(elem) {
  try {
    scroller(elem);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

if (!window.BaseJS.scrollers) {
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-scroller]'),
    initScroller,
  );

  const scrollerObserver = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      Array.prototype.forEach.call(mutation.addedNodes, (node) => {
        if (node.dataset && 'scroller' in node.dataset) {
          initScroller(node);
        } else if (node.querySelectorAll) {
          Array.prototype.forEach.call(
            node.querySelectorAll('[data-scroller]'),
            initScroller,
          );
        }
      });
    });
  });

  scrollerObserver.observe(document.body, {
    subtree: true,
    childList: true,
  });

  window.BaseJS.scrollers = true;
}

/*
 * placeholder figure
 * Drops a placeholder image onto loading figures before the main image has
 * loaded using CSS. This manipulates the classes used.
 */
function initPlaceholder(elem) {
  elem.classList.add('placeholder-image');
  const img = elem.querySelector('img');
  img.addEventListener('load', () => {
    elem.classList.remove('loading');
    elem.classList.add('loaded');
  });
  if (img && img.complete) {
    elem.classList.add('loaded');
  } else {
    elem.classList.add('loading');
  }
}

if (!window.BaseJS.placeholders) {
  Array.prototype.forEach.call(
    document.querySelectorAll('figure[data-placeholder]'),
    initPlaceholder,
  );

  const placeholderObserver = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      Array.prototype.forEach.call(mutation.addedNodes, (node) => {
        if (
          node.nodeName.toLowerCase() === 'figure' &&
          node.dataset &&
          'placeholder' in node.dataset
        ) {
          initPlaceholder(node);
        } else if (node.querySelectorAll) {
          Array.prototype.forEach.call(
            node.querySelectorAll('figure[data-placeholder]'),
            initPlaceholder,
          );
        }
      });
    });
  });

  placeholderObserver.observe(document.body, {
    subtree: true,
    childList: true,
  });

  window.BaseJS.placeholders = true;
}

/*
 * <details> related things
 */
// On page load, if we have a hash in the URL, check for a details element and open it if found.
(function () {
  if (document.location.hash) {
    const elem = document.querySelector(`details${document.location.hash}`);
    if (elem) {
      elem.open = true;
    }
  }
})();

// Add data-toggle-details with the ID of a details to toggle it on click
Array.prototype.forEach.call(
  document.querySelectorAll('[data-details-toggle]'),
  (elem) => {
    const target = document.querySelector(
      `details#${elem.dataset.detailsToggle}`,
    );
    if (target) {
      const handler = (ev) => {
        ev.preventDefault();
        target.open = !target.open;
      };
      elem.addEventListener('click', handler);
    }
  },
);

// Add data-toggle-open-target with the ID of a details to open it on click
Array.prototype.forEach.call(
  document.querySelectorAll('[data-details-open-target]'),
  (elem) => {
    const target = document.querySelector(
      `details#${elem.dataset.detailsOpenTarget}`,
    );
    if (target) {
      const handler = (ev) => {
        ev.preventDefault();
        target.open = true;
      };
      elem.addEventListener('click', handler);
    }
  },
);

/*
 * <form> related things
 */
// Add data-submit-on-change to an input to auto submit forms on change
// TODO: It looks like this is only used when the React JS doesn't kick in on
// the product listing forms so this may be able to go once we re-write those
// bits.
Array.prototype.forEach.call(
  document.querySelectorAll('.js input[data-submit-on-change]'),
  (elem) => {
    if (elem.dataset.submitOnChange !== 'inited') {
      elem.addEventListener('change', (ev) => ev.target.form.submit());
      elem.dataset.submitOnChange = 'inited';
    }
  },
);

/*
 * html-overlay-state sets the data-overlay attribute on <html> when we are
 * displaying some kind of overlay element
 */
(function () {
  if (window.BaseJS.overlayState) {
    return;
  }
  const html = document.getElementsByTagName('html')[0];
  const handler = () => {
    const isOpen =
      document.body.dataset.mainMenu === 'open' ||
      document.body.dataset.mobileSearch === 'open' ||
      document.body.classList.contains('jsm-off-canvas__left--open') ||
      document.body.classList.contains('jsm-off-canvas__right--open');
    html.dataset.overlay = isOpen ? 'open' : 'closed';
  };
  handler();
  const observer = new window.MutationObserver(debounce(handler));
  observer.observe(document.body, {attributes: true});
  window.BaseJS.overlayState = true;
})();

/*
 * <dialog> related things
 */
function initDialog(elem) {
  // Adds some helpers for opening and closing <dialog> elements
  Array.prototype.forEach.call(
    elem.querySelectorAll('[data-close]'),
    function (el) {
      el.addEventListener('click', function () {
        elem.close();
      });
    },
  );
  if (elem.id) {
    Array.prototype.forEach.call(
      document.querySelectorAll(`[data-dialog-show="${elem.id}"]`),
      function (el) {
        el.addEventListener('click', function () {
          elem.show();
        });
      },
    );
    Array.prototype.forEach.call(
      document.querySelectorAll(`[data-dialog-showmodal="${elem.id}"]`),
      function (el) {
        el.addEventListener('click', function () {
          elem.showModal();
        });
      },
    );
  }
}

if (!window.BaseJS.dialogs) {
  // Initialise any <dialog>s in the DOM…
  Array.prototype.forEach.call(
    document.getElementsByTagName('dialog'),
    initDialog,
  );

  // …and then set up a MutationObserver to initialise any <dialog>s added to
  // the DOM later.
  const dialogObserver = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      Array.prototype.forEach.call(mutation.addedNodes, (node) => {
        if (node.nodeName.toLowerCase() === 'dialog') {
          initDialog(node);
        } else if (node.querySelectorAll) {
          Array.prototype.forEach.call(
            node.querySelectorAll('dialog'),
            initDialog,
          );
        }
      });
    });
  });

  dialogObserver.observe(document.body, {subtree: true, childList: true});

  window.BaseJS.dialogs = true;
}
