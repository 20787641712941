import {closeMenu} from './category-menu.js';

const openState = {
  search: false,
  menu: false,
};

function toggleSearch(target) {
  target.classList.toggle('open');
  openState.search = target.classList.contains('open');
  document.body.dataset.mobileSearch = openState.search ? 'open' : 'closed';
}

function toggleMenu(target, doOpen) {
  if (doOpen === undefined) {
    doOpen = document.body.dataset.mainMenu !== 'open';
  }
  if (doOpen) {
    document.body.dataset.mainMenu = 'open';
  } else {
    closeMenu(target);
  }
  openState.menu = doOpen;
}

export function mobileMenuToggle(elem, options) {
  if (!('mainMenu' in document.body.dataset)) {
    document.body.dataset.mainMenu = 'closed';
  }
  if (!options.target) {
    return;
  }
  elem.addEventListener('click', (ev) => {
    ev.preventDefault();
    if (openState.search && options.searchTarget) {
      toggleSearch(options.searchTarget);
    }
    toggleMenu(options.target);
  });
}

export function mobileSearchToggle(elem, options) {
  document.body.dataset.mobileSearch = 'closed';
  if (!options.target) {
    return;
  }
  elem.addEventListener('click', (ev) => {
    ev.preventDefault();
    if (openState.menu && options.menuTarget) {
      toggleMenu(options.menuTarget, false);
    }
    toggleSearch(options.target);
  });
  const form = options.target.querySelector('form');
  if (form) {
    form.addEventListener('submit', () => {
      if (openState.search) {
        toggleSearch(options.target);
      }
    });
  }
}
